@import "../../global.scss";

.menu{
    @include mobile{
        width:100%;
        right:-100%;
    }
    width: 300px;
    height: 100vh;
    background: $mainColor1;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
    opacity: 1;

    &.active {
        right:0;
    }

    ul{
        @include mobile{
            width: 50%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        margin:0;
        padding:0;
        list-style: none;
        font-weight: 300;
        width: 60%;

        li{
            margin-bottom: 30px;
            a{
                text-decoration: none;
                color: $mainColor;
                font-size: 40px;
            }

            &:hover{
                font-weight:500;
            }
        }   
    }
}