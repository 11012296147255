@import "./global.scss";

.app{
    min-height: 100%;
    display: flex;
    position: relative;

    .sections{
        width: 100%;
        height: calc(100% - 70px);
        position: fixed;
        background-color: #FAF8F6;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //for firefox turns off scroll bar
        &::-webkit-scrollbar{
            display: none; // for other browser
        }

        > *{
            width: 100vw;
            height: 100%;
            scroll-snap-align: start;
        }
    }
}