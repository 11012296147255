@import "../../global.scss";

.container{
    overflow: hidden;
    background-color: $dark;
    width: 100%;
    height: 100%;
    position: relative;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .particle{
        @include mobile{
            display:none;
        }
        width: 100%;
        height: 100%;
        top:0;
        position: absolute;
        background-image: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;

    }
    .title{
        position: absolute;
        top: 50%;
        left: 50%;
        color: $mainColor;
        transform: translate(-50%,-50%);
        text-align: center;
        align-items: center;
        font-family: 'Roboto Slab', sans-serif;
        letter-spacing: 1px;
        z-index: 1;

        .click-here{
            transition: all 0.3s ease-in-out;
            opacity: 0;
            position:sticky;
            cursor: pointer;
            float: center;
            font-size: 45px;
            font-weight: 700;
            text-align: center;
            line-height: 50pt;
            display: inline;
            z-index: 5;
            text-decoration: underline;
            text-decoration-color: $mainColor2;
            text-decoration-thickness: 6px;
            border-radius: 5px;
            font-weight: 600;
            color: $mainColor;

            @include mobile{
                font-size:3vh;
                line-height: normal;
            }
        }
        .click-here:hover{
            background-color:$mainColor2;   
        }

        
        span{
            text-align: center;
            float: center;
            clear: both;
            display: inline;
            line-height: 50pt;
            font-size: 40px;
            font-weight: 600;
            
            

            @include mobile{
                font-size:3vh;
                line-height: normal;
            }
                
        }                    
    }

    .arrow-down{
        position:absolute;
        display: flex;
        bottom: 30px;
        font-size: large;
        left: 50%;
        width: 50px;
        margin-left: -25px;

    }

    img{
        width: 50px;
        animation: arrowBlink 3s infinite;
        color:white;
    }

    @keyframes arrowBlink {
        50%{
            opacity: 0.5;
        }
    }
}


