@import "../../global.scss";

.portfolio{
    background-color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
    height: 100%;
    width: 100%;

    .particle{
        @include mobile{
            display: none;
        }
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }

    .arrowleft{
        @include mobile{
            top: 75%;
            left: 10%;
        }
        height: 50px;
        position: absolute;
        left: 150px;
        transform:rotate(90deg);
        cursor: pointer;
        animation: arrowBlink 3s infinite;
        z-index: 1;

        &:hover{
            filter: brightness(70%);
        }
    }

    .arrowright{
        @include mobile{
            top:75%;
            right: 10%;
        }
        height: 50px;
        position: absolute;
        right: 150px;
        transform:rotate(-90deg);
        cursor: pointer;
        animation: arrowBlink 3s infinite;
        z-index: 1;

        &:hover{
            filter: brightness(70%);
        }
    }

    .portfolioTitle{
        @include mobile{
            font-size: 3.5vh;
            top: 5%;
        }
        font-size: 5vh;
        font-weight: 600;
        position: absolute;
        top: 5%;
        color: $mainColor;
        z-index: 1;
    }

    .plug{
        @include mobile{
            font-size: 2.5vh;
            line-height: normal;
            top: 14%;
            width:90%;
            justify-content: center;
            align-items: center;
            text-align: center;
            align-content: center;
        }
        position: absolute;
        font-size: 2.5vh;
        top: 15%;
        align-items: center;
        color: $mainColor;
        align-content: center;
        justify-content: center;
        text-align: center;
        line-height: normal;
        z-index: 1;

        a{
            text-decoration: underline;
            text-decoration-color: $mainColor2;
            text-decoration-thickness: 3px ;
            font-weight: 600;
            color: $mainColor;

            &:hover{
            background-color:$mainColor2;
            }
        }
    }

    .slider{
        height: 44%;
        display: flex;
        position: absolute;
        left:0;
        overflow: visible;
        transition: all 1s ease-out;
        overflow: hidden;
        z-index: 1;


        .slidercontain{
            width: 100vw;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: visible;
            overflow: hidden;

            .project{
                @include mobile{
                    width:100%;
                }
                width: 42%;
                height: 100%;
                background-color: $mainColor1;
                border: 50px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
                
                .leftside{
                    flex:4;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .leftcontain{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        text-align: center;

                        h2{
                            @include mobile{
                                overflow: hidden;
                                font-size: 4vw;

                            }
                            color:$mainColor;
                            font-weight: 500;
                            font-size: 3vh;
                        }

                        p{
                            @include mobile{
                                font-size: 1.5vh;
                                overflow: hidden;
                            }
                            color:$mainColor;
                            filter: brightness(100%);
                            font-size: 1.5vh;
                        }

                        span{
                            border: 1px;
                            border-style: solid;
                            border-radius: 5px;
                            width: 70%;
                            border-color: $mainColor;
                            padding: 5px;
                            cursor: pointer;
                            color:$mainColor;
                            overflow: hidden;
                            justify-content: center;
                            align-items: center;
                            font-weight: 300;

                            &:hover{
                                border-color:$mainColor2;
                                background-color: $mainColor2;
                                color:$mainColor1;
                                border-style: solid;
                            }

                            a{
                                @include mobile{
                                    font-size: 2vh;
                                }
                            font-size: 2vh;
                            text-decoration: none;
                            color: $mainColor;
                            }
                        }  
                    }
                }

                .rightside{
                    flex:8;
                    height: 100%;
                    background-color: $mainColor2;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    vertical-align: middle;
                    overflow: hidden;

                    p{
                        @include mobile{
                            font-size: 1.7vh;
                            position: absolute;
                        }
                        color:$mainColor;
                        position:absolute;
                        display: flex;
                        top:31%;
                        left:47%;
                        font-weight: 300;
                        font-size: 2vh;
                        word-spacing: 9999999999px;
                        align-self: center;
                        
                    }
                    
                    .comp{
                        position: relative;

                        height: 100%;
                    }

                    .pattern{
                        overflow: hidden;
                        height: 100%;
                        width: 100%;
                        position:absolute;
                        left:0;
                        top:0;
                    }
                }
            }
        }
    }
}
