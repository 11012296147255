@import "../../global.scss";

.about {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .background{
        @include mobile{
            display: none;
        }
        z-index: 0;
        overflow: hidden;
        height: 100%;
        width: 100;
        position:absolute;
        left:0;
        top:0;
        flex:3
    }

    .a-left{
        @include mobile{
            display: none;
        }
        flex:1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        overflow: hidden;

        .a-card.bg{
            background-color: $mainColor1;
            position: absolute;
            left: 80px;
            top: 50px;
            overflow: hidden;
        }

        .a-card{
            width: 60%;
            height: 70vh;
            border-radius: 30px;
            overflow: hidden;
            position: relative;

            .a-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        } 
    }
    .a-right{
        @include mobile{
            text-align: left;
        }
        flex:1;
        background-color: $mainColor;
        color: $mainColor1;
        border-radius: 30px;
        border-width: 20px;
        padding: 30px;
        position: relative;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        margin-right: 50px;
        font-size: 2.1vh;

        .a-title{
            font-size: 40px;
            font-weight: 600;
        }
        .a-sub{
            font-size: 20px;
        }
        li{
            display: list-item;
            list-style-position:inside;
            list-style-type: circle;
            padding-bottom: 10px;
        }
        li::marker{
            color: $mainColor2;
            content: "►";
        }
    }
}

@media screen and (max-width:480px) {
    .about{
      flex-direction: column;
      text-align: center;
      margin-top: 50px;
    }

    .a-left {
      width: 100%;
    }

    .a-card {
      height: 30vh;
    }

    .a-card.bg{
      display: none;
    }

    .a-right{
      padding: 20px;
    }
}