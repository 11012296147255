@import "../../global.scss";

.topbar{
    width:100%;
    height: 70px;
    background-color: $mainColor2;
    color: $mainColor;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 1s ease;

    .wrapper{
        //padding: 10px 30px;
        margin-top: 11px;
        margin-bottom: 10px;
        margin-right: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        

        .right-full{
            display: flex;
            justify-content: space-between;
            align-items: center;

            a{
                text-decoration: none;
                text-decoration-color: white;
                text-decoration-line: 3px;
                color: $mainColor;
                font-size: 22px;
                font-weight: 500;
                transition: all 0.1s ease;
            }
            a:hover{
                filter:brightness(60%);
            }  
        }

        .left{
            display:flex;
            align-items: center;
            justify-content: center;
 
            .logo{

                font-size: 40px;
                font-weight: 100px;
                text-decoration: none; 
                color: inherit; 
                transition: all 0.1s ease-in-out;         
            }
            .logo:hover{
                    filter:brightness(60%);
                }  
        }
        .right{
            display: none;
            .hamburger{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                transition: 0.1s ease-in-out;

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 1s ease;
                }
                
            }.hamburger:hover{
                    filter:brightness(60%);
                }
        }
    }
    &.active{
        background-color: $accentColor2;
        color: $mainColor;

        .hamburger{
            span{
                &:first-child{
                    border-color: $mainColor;
                    transform: rotate(45deg);
                };
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    background-color: $mainColor;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

@media screen and (max-width:700px) {
    .right{
        display: block !important;
    }
    .right-full{
        display: none !important;
    }

}